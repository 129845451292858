.my-legend .legend-title {
  text-align: left;
  font-weight: bold;
  font-size: 90%;
  }
.my-legend .legend-scale ul {
  margin: 0;
  margin-bottom: .5rem;
  padding: 0;
  float: left;
  display: flex;
  flex-direction: column;
  list-style: none;
  }
.my-legend .legend-scale ul li {
  font-size: .8rem;
  list-style: none;
  margin-left: 0;
  align-items: center;
  display: flex;
  line-height: 2.2vh;
  }
.my-legend ul.legend-labels li span {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  height: 2.8vh;
  width: 2.8vh;
  margin-right: .5rem;
  margin-left: 0;
  }
.my-legend .legend-source {
  font-size: 70%;
  color: #999;
  clear: both;
  }
.my-legend a {
  color: #777;
  }

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  
}
.cell {
  flex: 0 0 33.333%;
  height: 3.5vh;
  width: .4vw;
}

.label-x {
  font-weight: bold;
  margin-top: .2rem;
  display: flex;
}

.grid-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label-y {
  font-weight: bold;
  position: relative;
  left: 65%;
}

.rotate {
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
}